import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function EconomyReportGenerationImage({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '32px',
          height: 'auto',
        }
      }
    >
      <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 15.75C1 16.6364 1.17459 17.5142 1.51381 18.3331C1.85303 19.1521 2.35023 19.8962 2.97703 20.523C4.2429 21.7889 5.95979 22.5 7.75 22.5H25.75C27.0789 22.5024 28.3593 22.0007 29.3329 21.0962C30.3065 20.1916 30.9008 18.9515 30.996 17.6259C31.0911 16.3004 30.6801 14.9881 29.8457 13.9537C29.0113 12.9194 27.8157 12.24 26.5 12.0525C26.5146 9.50066 25.5995 7.03081 23.9255 5.10465C22.2516 3.1785 19.9335 1.92788 17.4045 1.58658C14.8755 1.24528 12.3088 1.83666 10.1842 3.25017C8.0595 4.66368 6.52233 6.80257 5.86 9.26701C4.45743 9.67609 3.22545 10.5292 2.3491 11.6982C1.47275 12.8672 0.999351 14.289 1 15.75Z"
          stroke="#12A566"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
