import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function EconomyReportTree({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '28px',
          height: 'auto',
        }
      }
    >
      <svg
        width="28"
        height="32"
        viewBox="0 0 28 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 9.49999C5.00042 8.26929 5.25324 7.05174 5.74284 5.92262C6.23245 4.79349 6.94842 3.77678 7.84652 2.93532C8.74462 2.09386 9.80576 1.44554 10.9643 1.03043C12.1229 0.615322 13.3543 0.442241 14.5825 0.521889C15.8106 0.601538 17.0093 0.932222 18.1046 1.4935C19.1999 2.05478 20.1684 2.83473 20.9502 3.78515C21.7321 4.73556 22.3108 5.83623 22.6505 7.01914C22.9901 8.20204 23.0836 9.44204 22.925 10.6625C24.6569 11.6401 26.0162 13.1638 26.7905 14.9956C27.5649 16.8274 27.7107 18.8641 27.2052 20.7876C26.6997 22.711 25.5714 24.4128 23.9965 25.6272C22.4216 26.8416 20.4888 27.5002 18.5 27.5H15.5V32H12.5V27.5H8.75C6.86935 27.5008 5.04485 26.8591 3.57881 25.6812C2.11277 24.5032 1.0932 22.8597 0.689026 21.023C0.284855 19.1863 0.520348 17.2666 1.35648 15.5821C2.19262 13.8975 3.57921 12.5492 5.2865 11.7605C5.09562 11.0222 4.99935 10.2626 5 9.49999ZM6.51501 14.498C5.43702 15.0076 4.5645 15.8689 4.04086 16.9401C3.51721 18.0113 3.3736 19.2289 3.63363 20.3926C3.89367 21.5562 4.54186 22.5968 5.47169 23.3433C6.40151 24.0898 7.55762 24.4977 8.75 24.5H18.5C19.8999 24.5014 21.2562 24.0134 22.3342 23.1202C23.4122 22.2271 24.144 20.9852 24.4028 19.6094C24.6616 18.2337 24.4313 16.8107 23.7516 15.5869C23.0719 14.363 21.9857 13.4154 20.681 12.908L19.4195 12.416C19.685 11.456 20 10.508 20 9.49999C20 8.47725 19.7386 7.47149 19.2405 6.5782C18.7425 5.68492 18.0244 4.93377 17.1544 4.39609C16.2844 3.8584 15.2914 3.55202 14.2697 3.50605C13.248 3.46008 12.2315 3.67604 11.3167 4.13343C10.402 4.59081 9.61929 5.27443 9.04304 6.11938C8.46679 6.96433 8.1161 7.94255 8.02425 8.96116C7.9324 9.97976 8.10245 11.0049 8.51826 11.9393C8.93406 12.8737 9.58181 13.6863 10.4 14.3L8.6 16.7C7.78546 16.0892 7.08045 15.3446 6.51501 14.498Z"
          fill="#12A566"
        />
      </svg>
    </div>
  );
}
