import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import NexLogoV2 from 'assets/logo/NexLogoV2';

import { ALPHA_NUMERIC } from 'utils/unmask';
import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
  getPartsFromDate,
} from 'utils/form';

import { EconomyReportDataProps } from 'economyReport/pages/EconomyReport';

import EconomyReportGenerationImage from 'assets/background/economy-report/EconomyReportGenerationImage';
import EconomyReportGenerationCo2 from 'assets/background/economy-report/EconomyReportCo2';
import EconomyReportTree from 'assets/background/economy-report/EconomyReportTree';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

type MonthAndYearProps = {
  date: string;
  spliter: string;
  options: Intl.DateTimeFormatOptions;
};

export default function FirstPageEconomyReport({
  economyReportData,
}: EconomyReportDataProps) {
  const {
    economyValue = '0',
    economyReports = [],
    totalEconomyValue = '0',
    chargeTotalCharged = '0',
    billConsumedEnergyCredits = {
      consumedEnergyCreditsTotal: '0',
    },
    generationUnitLegalName = '',
    billTotalValue = '0',
    generationUnitAddressCity = '-',
    generationUnitAddressState = '-',
    cooperativeMemberDocumentId = '-',
    generationUnitAddressStreet = '-',
    generationUnitPowerCapacity = '0',
    tariffFlagModalityModalityFlag = '-',
    receivedCreditsOnPeak = '0',
    receivedCreditsOffPeak = '0',
    receivedCredits = '0',
    usedCreditsOnPeak = '0',
    usedCreditsOffPeak = '0',
    usedCredits = '0',
    accumulatedCredits = '0',
    accumulatedCreditsOnPeak = '0',
    accumulatedCreditsOffPeak = '0',
    consumerUnitConsumptionGroupType = '-',
    billReferenceDate = new Date().getTime(),
    apportionmentCycleConsumerUnitPercentual = '0',
    consumerUnitPowerDistributionUnitIdentifier = '-',
  } = economyReportData || {};

  const economyReportsFill = useMemo(() => {
    const missingItems = 6 - economyReports.length;
    let emptyItems = [];

    if (missingItems > 0) {
      emptyItems = Array(missingItems).fill({});
    }

    return [...economyReports, ...emptyItems];
  }, [economyReports]);

  const HAS_CONSUMPTION_GROUP_TYPE_B = useMemo(
    () => consumerUnitConsumptionGroupType === ConsumptionGroupsType.B,
    [consumerUnitConsumptionGroupType]
  );

  const getMonthAndYear = useCallback(
    ({ date, spliter, options }: MonthAndYearProps) =>
      `${
        getPartsFromDate(date, options).charAt(0).toUpperCase() +
        getPartsFromDate(date, options).slice(1).replace('.', '')
      }${spliter}${getPartsFromDate(date, {
        year: 'numeric',
        timeZone: 'UTC',
      })}`,
    []
  );

  const getTariffFlagModalityFlag = useCallback(
    () =>
      [
        {
          key: TariffFlagModalityModalityFlagType.GREEN,
          flag: 'Bandeira Verde',
          color: 'border-l-primary',
        },
        {
          key: TariffFlagModalityModalityFlagType.YELLOW,
          flag: 'Bandeira Amarela',
          color: 'border-l-yellow-500',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK,
          flag: 'Bandeira Vermelha PT1',
          color: 'border-l-red-a1',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK_TWO,
          flag: 'Bandeira Vermelha PT2',
          color: 'border-l-red-a1',
        },
      ].find(({ key }) => key === tariffFlagModalityModalityFlag),
    [tariffFlagModalityModalityFlag]
  );

  const HAS_CONSUMER_UNIT_INFO = useMemo(
    () => [
      {
        key: 'Unidade consumidora',
        value: consumerUnitPowerDistributionUnitIdentifier,
      },
      {
        key: 'Documento',
        value: cooperativeMemberDocumentId,
      },
    ],
    [cooperativeMemberDocumentId, consumerUnitPowerDistributionUnitIdentifier]
  );

  const HAS_GENERATION_UNIT_INFO = useMemo(
    () => [
      {
        key: 'Potência instalada:',
        value: +generationUnitPowerCapacity / 1000 + 'MW',
      },
      {
        key: 'Percentual alugado:',
        value: apportionmentCycleConsumerUnitPercentual + '% ALOCADO',
      },
      {
        key: 'Endereço:',
        value: `${generationUnitAddressStreet}, ${generationUnitAddressCity} - ${generationUnitAddressState}`,
      },
    ],
    [
      generationUnitAddressCity,
      generationUnitAddressState,
      generationUnitPowerCapacity,
      generationUnitAddressStreet,
      apportionmentCycleConsumerUnitPercentual,
    ]
  );

  const HAS_BILLING_INFO = useMemo(
    () => [
      {
        key: 'Identificador da fatura',
        value: `RE-${ALPHA_NUMERIC(
          consumerUnitPowerDistributionUnitIdentifier
        )}-${ALPHA_NUMERIC(
          formatDateToLocaleDateString(billReferenceDate as string, {
            year: '2-digit',
            month: '2-digit',
          })
        )}`,
      },
      {
        key: 'Mês de referência',
        value: getMonthAndYear({
          spliter: ' de ',
          options: {
            month: 'long',
            timeZone: 'UTC',
          },
          date: billReferenceDate as string,
        }),
      },
      {
        key: 'Bandeira tarifária',
        value: getTariffFlagModalityFlag()?.flag,
      },
    ],
    [
      getMonthAndYear,
      billReferenceDate,
      getTariffFlagModalityFlag,
      consumerUnitPowerDistributionUnitIdentifier,
    ]
  );

  const emissionFactor = 0.08358;
  const tc02 = (
    Number(billConsumedEnergyCredits.consumedEnergyCreditsTotal) *
    (emissionFactor / 100)
  ).toFixed(2);
  const trees = Math.round((Number(tc02) * 12) / 0.0134 / 12);

  const ACCUMULATED_AND_RECEIVED_CREDITS = useMemo(
    () => Math.round(+accumulatedCredits + +receivedCredits),
    [accumulatedCredits, receivedCredits]
  );

  const ACCUMULATED_AND_USED_CREDITS = useMemo(
    () => Math.round(+accumulatedCredits + +usedCredits),
    [accumulatedCredits, usedCredits]
  );

  const REMAINING_CREDITS = useMemo(
    () =>
      Math.round(
        +accumulatedCredits +
          +receivedCredits -
          (+usedCreditsOnPeak + +usedCreditsOffPeak)
      ),
    [accumulatedCredits, receivedCredits, usedCreditsOffPeak, usedCreditsOnPeak]
  );

  const REMAING_CREDITS_ECONOMY = useMemo(() => {
    if (
      ACCUMULATED_AND_RECEIVED_CREDITS > 0 &&
      ACCUMULATED_AND_USED_CREDITS > 0 &&
      REMAINING_CREDITS > 0
    ) {
      return REMAINING_CREDITS;
    } else {
      return 0;
    }
  }, [
    ACCUMULATED_AND_RECEIVED_CREDITS,
    ACCUMULATED_AND_USED_CREDITS,
    REMAINING_CREDITS,
  ]);

  return (
    <div
      id="economy-report-first-page"
      className="font-urbane bg-gradient-to-b flex flex-col items-center justify-start relative h-auto py-10 px-10 gap-2"
      style={{ width: '59rem' }}
    >
      <header className="w-full flex items-center justify-between pb-2">
        <h1 className="font-semibold text-white text-3.5xl tracking-wider">
          Relatório de Economia
        </h1>
        <NexLogoV2 />
      </header>
      <div className="bg-white w-full h-auto py-8 px-8 flex flex-col gap-6 border border-gray border-solid rounded-lg">
        <section className="flex w-full items-start justify-between gap-12">
          <div
            id="economy-report-billing-info"
            className="flex flex-col items-start justify-start w-full justify-center py-3 text-gray-dark130 text-sm tracking-wider-1 h-auto gap-1"
          >
            <h3 className="font-semibold text-2xl">Razão Social</h3>
            {HAS_CONSUMER_UNIT_INFO.map((item) => (
              <p key={item.key} className="font-normal">
                {item.key}:{' '}
                <span className="font-semibold">{item.value || '-'}</span>
              </p>
            ))}
          </div>
          <div
            id="economy-report-billing-info-v2"
            className={classNames(
              'flex flex-col bg-green-light w-full border border-green-dark500 border-solid py-4 px-8 rounded-lg border-l-3 text-sm text-gray-dark130 justify-center h-auto',
              {
                [getTariffFlagModalityFlag()?.color as string]:
                  !!getTariffFlagModalityFlag(),
              }
            )}
          >
            {HAS_BILLING_INFO.map((item) => (
              <div className="flex flex-row" key={item.key}>
                <p className="font-normal pr-1 leading-6 text-gray-dark500">
                  {item.key}:{' '}
                </p>
                <span className="font-semibold leading-6">{item.value || '-'}</span>
              </div>
            ))}
          </div>
        </section>
        <section className="flex flex-row w-full">
          <EconomyReportGenerationImage className="border border-r-transparent border-gray border-solid rounded-xl" />
          <div
            className="flex h-auto bg-green-dark800 text-green-light flex-col w-full border border-l-transparent border-gray border-solid rounded-lg rounded-s-none justify-center gap-1"
            style={{
              padding: '2.5rem 0 2.5rem 5.5rem',
            }}
          >
            <h1
              id="economy-report-generation-unit-name"
              className="font-semibold pb-2 leading-6 text-gray-dark51 text-2xl"
            >
              {generationUnitLegalName}
            </h1>
            {HAS_GENERATION_UNIT_INFO.map((item, i) => {
              const index = i === 2;

              return (
                <div
                  key={item.key}
                  className={classNames('flex text-sm gap-1', {
                    'flex-row': !index,
                    'flex-col': index,
                  })}
                >
                  <h3 className="font-normal">{item.key}</h3>
                  <p className="font-semibold">{item.value}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section className="flex flex-col gap-3 w-full">
          <div className="flex flex-col items-start mt-1 mb-1">
            <h1 className="text-gray-dark-51 font-semibold text-2xl">
              Entenda a sua economia NEX:
            </h1>
            <p className="text-gray-dark500 font-normal tracking-wider-1 text-sm ">
              *Esses valores são cálculos aproximados
            </p>
          </div>
          <div className="flex flex-row justify-center gap-3">
            <div className="w-full border border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg">
              <div className="flex flex-row items-center p-5 pl-8 border-b border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg bg-green-dark400">
                <h3 className="font-semibold text-gray-dark130">Sem a NEX</h3>
              </div>
              <div
                className="flex flex-col justify-center items-center bg-gray-dark200 text-gray-dark130 font-normal tracking-wider-1 text-sm"
                style={{
                  height: '210px',
                }}
              >
                <p>Fatura da concessionária</p>
                <p className="font-semibold text-3xl">
                  {convertToBRL(
                    String(
                      Number(chargeTotalCharged) +
                        Number(billTotalValue) +
                        Number(economyValue)
                    )
                  )}
                </p>
              </div>
            </div>
            <div className="w-full border border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg">
              <div className="flex flex-row items-center p-5 pl-8 border-b border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg bg-green-dark400">
                <h3 className="font-semibold text-gray-dark130">Com a NEX</h3>
              </div>
              <div
                className="flex flex-col gap-2 justify-center items-center bg-gray-dark200 text-gray-dark130 font-normal tracking-wider-1 text-sm"
                style={{
                  height: '210px',
                }}
              >
                <p className="font-semibold text-3xl">
                  {convertToBRL(
                    String(Number(chargeTotalCharged) + Number(billTotalValue))
                  )}
                </p>
                <div className="flex flex-col items-center">
                  <p>
                    Concessionária:{' '}
                    <strong className="font-semibold">
                      {convertToBRL(billTotalValue)}
                    </strong>
                  </p>
                  <p>
                    Boleto NEX:{' '}
                    <strong className="font-semibold">
                      {convertToBRL(chargeTotalCharged)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full border border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg">
              <div className="flex flex-row items-center p-5 pl-8 border-b border-green-dark500 border-solid rounded-tl-lg rounded-tr-lg bg-green-dark400">
                <h3 className="font-semibold text-gray-dark130">Sua economia NEX</h3>
              </div>
              <div
                className="flex flex-col bg-gradient-to-t justify-center items-center text-center bg-primary text-white font-normal tracking-wider-1 text-sm"
                style={{
                  height: '210px',
                }}
              >
                <p>
                  Você teve uma <br></br>economia de
                </p>
                <p className="font-semibold text-3xl">
                  {convertToBRL(economyValue)}
                </p>
              </div>
            </div>
          </div>
          <div
            id="economy-report-notice-message"
            className="flex flex-row bg-green-light items-center text-sm text-green-500 justify-center w-full border rounded border-green-dark500 border-solid p-4 h-auto"
          >
            <p className="font-semibold pr-1">Atenção:</p>
            <p className="font-regular">
              Com a NEX você paga duas faturas, mas paga menos no valor total.
            </p>
          </div>
        </section>
        <section className="flex flex-col gap-4 w-full">
          <h3 className="text-gray-dark51 font-medium text-xl">
            Seu histórico de economia e seus impactos:
          </h3>
          <div className="flex flex-row justify-between gap-3">
            <div
              className="flex flex-col justify-end border border-green-dark500 border-solid text-xs text-gray-dark130 rounded-tl-lg rounded-tr-lg"
              style={{ width: '120%' }}
            >
              <div>
                {economyReportsFill &&
                  economyReportsFill.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row items-center h-10 justify-between px-8 border-green-dark500 border-solid border-b economy-report-bill"
                    >
                      {item.economyReferenceDate && (
                        <>
                          <p className="text-gray-dark500">
                            {getMonthAndYear({
                              date: item.economyReferenceDate,
                              options: {
                                month: 'short',
                                timeZone: 'UTC',
                              },
                              spliter: '/',
                            }).toLowerCase() + ':'}
                          </p>
                          <p className="font-semibold text-gray-dark130">
                            {convertToBRL(item.economyValue)}
                          </p>
                        </>
                      )}
                    </div>
                  ))}
              </div>
              <div>
                <div
                  id="economy-report-bill-total"
                  className="flex justify-center py-3 px-5 items-center border-b border-green-dark500 border-solid bg-gray-dark200"
                >
                  <p className="text-gray-dark500">
                    ECON. EM 6 MESES:{' '}
                    <strong className="text-gray-dark130 font-semibold">
                      {convertToBRL(
                        String(
                          economyReports?.reduce(
                            (acc, item) => acc + +item.economyValue,
                            0
                          )
                        )
                      )}{' '}
                    </strong>
                  </p>
                </div>
                <div
                  id="economy-report-bill-total-v2"
                  className="flex justify-center py-3 px-5 items-center bg-primary"
                >
                  <p>
                    TOTAL ECON. NEX:{' '}
                    <strong className="text-gray-dark51 font-semibold">
                      {convertToBRL(totalEconomyValue)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full gap-2 flex flex-col text-sm items-center justify-center text-center border border-green-dark500 border-solid rounded-lg px-4 py-10">
              <div>
                <p className="text-gray-dark500">Créditos Tranferidos</p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p className="font-semibold text-center">
                      {Math.round(+(receivedCreditsOnPeak + receivedCreditsOffPeak))}
                    </p>
                  </>
                ) : (
                  <p className="font-semibold text-center">
                    {Math.round(+receivedCredits)}
                  </p>
                )}
              </div>
              <hr
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '1px',
                  border: '0',
                  backgroundColor: '#ccc',
                  margin: '5px 0',
                }}
              />
              <div>
                <p className="text-gray-dark500">Créditos Utilizados</p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p className="font-semibold text-center">
                      {Math.round(+(usedCreditsOnPeak + usedCreditsOffPeak))}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-semibold text-center"
                  >
                    {Math.round(+usedCredits)}
                  </p>
                )}
              </div>
              <hr
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '1px',
                  border: '0',
                  backgroundColor: '#ccc',
                  margin: '5px 0',
                }}
              />
              <div>
                <p className="text-gray-dark500">Créditos Acumulados</p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p className="font-semibold text-center">
                      {Math.round(
                        +(accumulatedCreditsOnPeak + accumulatedCreditsOffPeak)
                      )}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-semibold text-center"
                  >
                    {Math.round(+accumulatedCredits)}
                  </p>
                )}
              </div>
              <hr
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '1px',
                  border: '0',
                  backgroundColor: '#ccc',
                  margin: '5px 0',
                }}
              />
              <div>
                <p className="text-gray-dark500">Créditos Restantes</p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p className="font-semibold text-center">
                      {Math.round(
                        +accumulatedCreditsOffPeak +
                          +receivedCreditsOffPeak -
                          +usedCreditsOffPeak
                      ) +
                        Math.round(
                          +accumulatedCreditsOnPeak +
                            +receivedCreditsOnPeak -
                            +usedCreditsOnPeak
                        )}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-semibold text-center"
                  >
                    {REMAING_CREDITS_ECONOMY}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full flex gap-6 flex-col items-center justify-center text-center border border-green-dark500 border-solid rounded-lg px-4 py-10">
              <div className="flex flex-col items-center">
                <EconomyReportTree />
                <p className="text-gray-dark500 font-normal text-lg">Você plantou</p>
                <p className="text-gray-dark51 font-semibold text-3xl">{trees}</p>
              </div>
              <div className="flex flex-col items-center">
                <EconomyReportGenerationCo2 />
                <p className="text-gray-dark500 font-normal text-lg">
                  Deixou de emitir <br /> CO2 na atmosfera
                </p>
                <p className="text-gray-dark51 font-semibold text-3xl">
                  {convertToDecimal(tc02)} tco
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
